import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    Box, Card, CardContent, CircularProgress, Grid,
    IconButton, Menu,
    MenuItem
} from '@mui/material';
import Typography from '@mui/material/Typography';
import { useTranslation } from "react-i18next";
import pdf from "../../img/pdf_icon.png";
import doc from "../../img/doc_icon.png";
import file from "../../img/file_icon.png";
import { dayjsToTimeString } from "../../utils";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import WarningIcon from '@mui/icons-material/Warning';
import { Icon } from ".";
import { useTheme } from "@emotion/react";

export const DocumentCard = ({ document, index_name, deleteDocument }) => {
    const navigate = useNavigate();
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        event.preventDefault();
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDelete = (e) => {
        deleteDocument(index_name, document.id).then(() => {
            handleClose();
        });
        e.preventDefault();
    };

    const handleDetail = () => {
        navigate(`/${process.env.REACT_APP_PATH}/documentindexes/${encodeURIComponent(index_name)}/${document.id}`);
    };

    return (
        <>
            <Grid container
                spacing={1}
                alignItems="center"
                justifyContent="center"
                direction="column"
                sx={{
                    opacity: document.state !== "PROCESSED" ? 0.5 : 1,
                }}
            >

                <Grid item xs={12} sx={{ cursor: 'pointer' }}
                    onClick={handleDetail}
                >

                    <Box sx={{ position: "relative" }}>
                        <Icon align='center' alignItems='center' justifyContent='center'
                            src={document.document_path && document.document_path.toLowerCase().endsWith('pdf') ?
                                pdf : document.document_path && (document.document_path.toLowerCase().endsWith('doc') || document.document_path.toLowerCase().endsWith('docx')) ? doc : file}
                            sx={{
                                width: 'auto',
                                height: '100px',
                            }} />
                        {document.state === "PROCESSING" &&
                            <CircularProgress color="info" sx={{ opacity: 1, position: "absolute", top: "calc(16px + 10%)", left: "calc(10px + 10%)", width: "50px !important", height: "50px !important" }}  />}
                        {document.state === "FAILED" &&
                            <WarningIcon color="error" sx={{ opacity: 1, position: "absolute", top: "calc(6px + 10%)", left: "calc(6px + 10%)", width: "70% !important", height: "70% !important" }} />}
                    </Box>
                </Grid>

                {document.score &&
                    <Grid item xs={12}>
                        <Card elevation={0} sx={{ backgroundColor: document.relevant ? 'secondary.light' : 'background.grey' }}>
                            <CardContent sx={{padding: 1, paddingBottom: `${theme.spacing(1)} !important`}}>
                                <Typography variant="body1" gutterBottom align="center">
                                    {`${(document.score * 100).toFixed(0)}%`}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                }


                <Grid item xs={12} sx={{ cursor: 'pointer', maxWidth: '100% !important' }}
                    onClick={handleDetail} 
                >
                    <Typography variant="h7" gutterBottom align="center" sx={{maxWidth: '100% !important'}}>
                        {document.name}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={1} alignItems="center" justifyContent="center" direction="row">
                        <Grid item xs={2}>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="body2" gutterBottom align="center">
                                {`Created: ${dayjsToTimeString(document.created_at, i18n.language)}`}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <IconButton aria-label="edit" size="small" onClick={handleClick}>
                                <MoreVertIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Menu open={open} anchorEl={anchorEl} onClose={handleClose}>
                <MenuItem disabled onClick={handleClose}>{t("EDIT")}</MenuItem>
                <MenuItem onClick={handleDelete}>{t("DELETE")}</MenuItem>
            </Menu>
        </>
    );
};
