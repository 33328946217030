import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LogoutIcon from '@mui/icons-material/Logout';
import { Alert, Button, Card, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ErrorBoundary } from "react-error-boundary";
import logo from '../../img/logo.png'
import { logout } from "../../redux/actions/auth";
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import FolderIcon from '@mui/icons-material/Folder';
import { fetchDocumentIndexes } from '../../redux/actions/documentindex';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import LensIcon from '@mui/icons-material/Lens';
import { PATHS } from "../../constants";
import { styled } from "@mui/system";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton/IconButton";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { showHistory } from "../../redux/actions/global";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import QueryHistory from './QueryHistory';
import Loading from "../../components/Loading";
import { useSetState } from 'react-use';
import Joyride, { CallBackProps, STATUS, Step } from 'react-joyride';
import { useTheme } from '@emotion/react';
import { alpha } from "@mui/material/styles";
import AppTour from './AppTour';

const DRAWER_WIDTH = 250;

export const MenuLinksContext = React.createContext([]);
export const FetchErrorContext = React.createContext([]);


const RightDrawer = styled(Paper)(({ theme, shown }) => {
    return {
        zIndex: 500,
        display: shown ? 'block' : 'none',
        width: shown ? '500px' : '0px',
        height: '100vh',
        position: 'absolute',
        right: 0,
        top: 0,
        borderBottomRightRadius: 0,
        borderTopRightRadius: 0,
        borderBottomLeftRRadius: 0,
        //borderTopLeftRadius: 0,
        backgroundColor: theme.palette.background.grey,
        overflow: 'auto',

    }
});

const Page = ({
    user,
    logout,
    children,
    documentIndexes,
    fetchDocumentIndexes,
    history,
    showHistory,
}) => {
    const { t } = useTranslation();
    const [fetchError, setFetchError] = React.useState(null);
    const [menuLinks, setMenuLinks] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [bindersOpen, setBindersOpen] = React.useState(true);
    const location = useLocation();
    const rightDrawerRef = React.useRef(null);

    const handleClick = () => {
        if (location.pathname.endsWith(PATHS.DOCUMENT_INDEXES)) {
            setBindersOpen(!bindersOpen);
        } else {
            setBindersOpen(true);
        }

    };

    React.useEffect(() => {
        function handleClickOutside(event) {
            if (rightDrawerRef?.current && !rightDrawerRef.current.contains(event.target)) {
                showHistory(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [rightDrawerRef]);

    React.useEffect(() => {
        if (user) {
            if (!documentIndexes) {
                fetchDocumentIndexes().catch((error) => {
                    if (error?.message) {
                        setFetchError(error.message);
                    } else {
                        setFetchError("Unexpected error occured");
                    }
                });
            } else {
                const links = documentIndexes.map((index) => ({
                    text: index.index_name,
                    href: `/${process.env.REACT_APP_PATH}/${PATHS.DOCUMENT_INDEXES}/${encodeURIComponent(index.index_name)}`,
                    icon: () => (<FolderIcon />)
                }));

                setMenuLinks(links);
                setLoading(false);
            }
        }
    }, [documentIndexes, fetchDocumentIndexes, user]);


    if (!user) {
        return <Loading></Loading>
    }

    return (
        <>
            <AppTour />

            <Drawer
                sx={{
                    width: DRAWER_WIDTH,
                    flexShrink: 0,
                    maxHeight: "100%",
                    '& .MuiDrawer-paper': {
                        width: DRAWER_WIDTH,
                        boxSizing: 'border-box',
                        top: 0,
                        height: 'auto',
                        maxHeight: "100%",
                        bottom: 0,
                        paddingLeft: 2,
                        paddingRight: 2,
                        borderRight: '5px solid rgba(0, 0, 0, 0.05)'
                    },
                }}
                variant="permanent"
                anchor="left"
            >
                <img src={logo} alt='Doxtory Logo' style={{
                    height: 'auto',
                    width: '149px',
                    marginRight: 'auto',
                    marginLeft: 'auto',
                    marginTop: '10%',
                    marginBottom: '5%'
                }} />

                <List sx={{maxHeight: "calc(100% - 260px)"}}>
                    <ListItem id="home-button" key={`${process.env.REACT_APP_PATH}/`} disablePadding>
                        <ListItemButton component={Link} to={`${process.env.REACT_APP_PATH}/`}>
                            <ListItemText primary={'Home'} />
                        </ListItemButton>
                    </ListItem>
                    <ListItemButton id="binders-button" component={Link} to={`/${process.env.REACT_APP_PATH}/${PATHS.DOCUMENT_INDEXES}`}
                        onClick={handleClick}>
                        <ListItemText primary="Binders" />
                        {bindersOpen ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>

                    {loading && <Loading />}

                    <Collapse id="binders-list" in={bindersOpen} timeout="auto" unmountOnExit
                            sx={{
                                overflow: 'auto',
                                maxHeight: "calc(100% - 90px)",
                            }}>
                        <List component="div" disablePadding>
                            {documentIndexes && documentIndexes.map((index) => (
                                <ListItem sx={{ pl: 4 }}
                                    key={`/${process.env.REACT_APP_PATH}/${PATHS.DOCUMENT_INDEXES}/${encodeURIComponent(index.index_name)}`}
                                    disablePadding>
                                    <ListItemButton component={Link}
                                        to={`/${process.env.REACT_APP_PATH}/${PATHS.DOCUMENT_INDEXES}/${encodeURIComponent(index.index_name)}`}>
                                        <ListItemIcon>
                                            <LensIcon sx={{ color: index.color }} fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText primary={index.index_name} />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    </Collapse>
                </List>
                <Divider sx={{ mt: 'auto' }} />
                <List sx={{maxHeight: "20vh"}}>
                    <ListItem key={`${process.env.REACT_APP_PATH}/settings`} disablePadding>
                        <ListItemButton component={Link} to={`${process.env.REACT_APP_PATH}/settings/profile`}>
                            <ListItemText primary={"Settings"} />
                        </ListItemButton>
                    </ListItem>
                    <ListItem id="archive-button" key={"logout-list-item"} disablePadding>
                        <ListItemButton component={Link} to={`/${process.env.REACT_APP_PATH}/${PATHS.ARCHIVED_DOCUMENTS}`}>
                            <ListItemText primary={t('ARCHIVE')} />
                        </ListItemButton>
                    </ListItem>
                    {user && (
                        <ListItemButton id="logout-button" key='Logout' sx={{
                            borderBottom: theme => `2px ${theme.palette.background.grey100} solid`
                        }}>

                            <ListItemIcon ><AccountCircleIcon /></ListItemIcon>
                            <ListItemText
                                primary={user.last_name && user.first_name && `${user.first_name} ${user.last_name}`} />


                            <ListItemIcon>
                                <LogoutIcon onClick={logout} />
                            </ListItemIcon>
                        </ListItemButton>
                    )
                    }
                </List>
            </Drawer>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    bgcolor: 'background.default',
                    mt: 0,
                    p: 3,
                    maxWidth: "calc(100vw - 250px)"
                }}
            >
                <MenuLinksContext.Provider value={[menuLinks, setMenuLinks]}>
                    <ErrorBoundary
                        onError={(error, info) => error.message ? setFetchError("Unexpected error occured: " + error.message) : setFetchError("Unexpected error occured")}>
                        {children}
                    </ErrorBoundary>
                </MenuLinksContext.Provider>
            </Box>
            <RightDrawer ref={rightDrawerRef} shown={history}>
                <QueryHistory />
            </RightDrawer>
            <Snackbar open={!!fetchError} autoHideDuration={6000} onClose={() => setFetchError(null)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                <Alert onClose={() => setFetchError(null)} severity="error" sx={{ width: '100%' }}>
                    {fetchError}
                </Alert>
            </Snackbar>
        </>
    );
}

Page.propTypes = {
    user: PropTypes.object,
};

Page.defaultProps = {
    user: null,
};

const mapStateToProps = (store) => ({
    user: store.authData.user,
    documentIndexes: store.documentIndexData.documentIndexes,
    history: store.globalData.history
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    logout,
    fetchDocumentIndexes,
    showHistory,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Page);
