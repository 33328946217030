import { EventSourcePolyfill } from 'event-source-polyfill';
import httpService from './http.service';


const getDocumentSingle = (documentIndex, documentId) => httpService
    .get(`/document_index/${documentIndex}/document/${documentId}`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const getDocuments = (documentIndex) => httpService
    .get(`/document_index/${documentIndex}/document`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));


const createDocument = (documentIndex, document) => httpService
    .post(`/document_index/${documentIndex}/document`, document)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err));

const searchDocument = (documentIndex, query, historyId, fullAnswers) => httpService
    .get(`/document_index/${documentIndex}/search?query=${encodeURIComponent(query)}${historyId != null ? `&queryHistoryId=${historyId}` : ''}${fullAnswers ? `` : '&only_docs=true'}`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err));


const chat = (documentIndex, query, historyId, prevHistoryId) => httpService
    .get(`/document_index/${documentIndex}/chat?query=${encodeURIComponent(query)}${historyId != null ? `&queryHistoryId=${historyId}` : ''}${prevHistoryId != null ? `&prevHistoryId=${prevHistoryId}` : ''}`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err));

const chatStreaming = (documentIndex, query, historyId, prevHistoryId, messageCallback = (ev, sse) => { },
    errorCallback = (errEv, sse) => { }, openCallback = (openEv, sse) => { }) => {
    const accessToken = localStorage.getItem(httpService.USER_TOKEN_KEY);
    const authHeader = { Authorization: `Bearer ${accessToken}` };

    const sse = new EventSourcePolyfill(
        `${httpService.baseURL}/document_index/${documentIndex}/chat_stream?query=${encodeURIComponent(query)}${historyId != null ? `&queryHistoryId=${historyId}` : ''}${prevHistoryId != null ? `&prevHistoryId=${prevHistoryId}` : ''}`,
        {
            headers: authHeader,
            heartbeatTimeout: 180000.
        },
    );

    sse.addEventListener('message', (ev) => messageCallback(ev, sse));
    sse.addEventListener('error', (ev) => errorCallback(ev, sse));
    sse.addEventListener('open', (ev) => openCallback(ev, sse));

    return sse;
}

const deleteDocument = (documentIndex, documentId) => httpService
    .remove(`/document_index/${documentIndex}/document/${documentId}`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err));

const getDocumentPrepareDownload = (documentIndex, documentId) => httpService
    .get(`/document_index/${documentIndex}/document/${documentId}/download`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));


const getDocumentDownloadURI = (documentIndex, documentId, key) => `${httpService.baseURL}/document_index/${documentIndex}/document/${documentId}/download/${key}`;


export default {
    getDocuments,
    createDocument,
    searchDocument,
    getDocumentSingle,
    deleteDocument,
    chat,
    getDocumentPrepareDownload,
    getDocumentDownloadURI,
    chatStreaming,
};
