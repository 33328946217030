import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogActions from '@mui/material/DialogActions';
import doc from "../../img/doc_icon.png";
import file from "../../img/file_icon.png";
import pdf from "../../img/pdf_icon.png";
import {
    Alert,
    Checkbox,
    CircularProgress,
    FormControl,
    FormControlLabel,
    FormLabel,
    IconButton, OutlinedInput,
    Radio,
    RadioGroup,
    Typography,
    Paper,
    List, ListItem, ListItemText,
    ListItemIcon,
    Icon
} from "@mui/material";
import Button from "@mui/material/Button";
import ClearIcon from "@mui/icons-material/Clear";
import { styled } from "@mui/system";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Loading from '../Loading';
import { InsertDriveFileRounded, SaveRounded, Edit, Delete } from '@mui/icons-material';
import { MAX_NUMBER_OF_FILES } from '../../utils/constants';

const CloseButton = styled(IconButton)(({ theme }) => ({
    left: 10,
    color: (theme) => theme.palette.primary.contrastText

}));

const DropZone = styled(Paper)(({ theme }) => ({
    left: 10,
    borderColor: theme.palette.secondary.main,
    borderStyle: 'dashed',
    borderWidth: '2px',
    height: 160,
    width: '100%'

}));

const FormFields = styled('div')(({ theme }) => ({
    height: '100%',
    width: '100%',
    flexDirection: 'row',

    alignItems: 'flex-start',
    paddingTop: '50px',

}));

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

function DocumentUpdate(props) {
    const {
        handleClose,
        createDocument,
        indexConfigModel,
        saving,
        indexName
    } = props;


    const { t } = useTranslation();

    const [errorStep1, setErrorStep1] = useState('');
    const [names, setNames] = React.useState([]);
    const [urlName, setUrlName] = React.useState("");
    const [url, setUrl] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [uploadDoc, setUploadDoc] = React.useState(false);
    const [files, setFiles] = React.useState([]);
    const [crawlerDepth, setCrawlerDepth] = React.useState(0);
    const [ocr, setOcr] = React.useState(false);
    const [isDragActive, setIsDragActive] = React.useState(false);

    const submit = () => {
        setLoading(true);

        const form = new FormData();
        form.append('url', url);
        form.append('name', urlName);
        form.append('uploadDoc', uploadDoc);
        form.append('crawlerDepth', crawlerDepth);
        form.append('useOcr', ocr);
        if (uploadDoc) {
            for (let i = 0; i < files.length; i++) {
                const newFile = new File([files[i]], names[i].name, { type: files[i].type })
                form.append('files', newFile);
            }
        }

        createDocument(indexName, form).then(() => {
            setLoading(false);
            setNames([]);
            setUrl('');
            setFiles([]);
            setUrlName("");
            handleClose();
        }).catch((error) => {
            setLoading(false);
            console.log(error);
        });
    }

    useEffect(() => {

    }, [indexConfigModel]);

    const isValid = (isNew) => {
        let ret = true;
        const error = {};
        setErrorStep1(error);
        return ret;
    }

    const handleDragEnter = () => setIsDragActive(true);
    const handleDragLeave = (event) => {
        const container = event.currentTarget;
        if (!container.contains(event.relatedTarget)) {
            setIsDragActive(false)
        }
    };

    const handleDrop = (event) => {
        event.preventDefault();
        setIsDragActive(false);

        const names = Array.from(event.dataTransfer.files).map((file) => ({
            name: file.name
        }));
        setNames((prevNames) => [...prevNames, ...names])
        setFiles((prevFiles) => [...prevFiles, ...Array.from(event.dataTransfer.files)]);
    };

    const handleNameChange = (index, newName) => {
        setNames((prevNames) =>
            prevNames.map((file, idx) =>
                idx === index ? { ...file, name: newName } : file
            )
        )
    };

    const handleRemoveFile = (index) => {
        setNames((prevNames) => prevNames.filter((_, idx) => idx !== index));
        setFiles((prevFiles) => prevFiles.filter((_, idx) => idx !== index))
    }

    const handleSelect = (event) => {
        const names = Array.from(event.target.files).map((file) => ({
            name: file.name
        }));

        setNames((prevNames) => [...prevNames, ...names])
        setFiles((prevFiles) => [...prevFiles, ...Array.from(event.target.files)]);
    };


    return (

        <Dialog
            maxWidth="md"
            sx={{
                '& .MuiDialog-paper': {
                    width: '100%',
                    height: '80%',
                },
            }}
            open={indexConfigModel.open}
            onClose={handleClose}
            isDragActive={isDragActive}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDragOver={(e) => e.preventDefault()}
        >

            <DialogTitle sx={{
                height: '40px',
                paddingLeft: 5,
                paddingRight: 5,
                display: 'flex', alignItems: 'center', justifyContent: 'space-between',
                width: '100%',
            }}>
                <>
                    <Typography variant='h7'
                        sx={{ color: (theme) => theme.palette.background.contrastText }}>{indexConfigModel.isNew ? t('CREATE') : t('EDIT')}</Typography>
                </>


                <CloseButton aria-label="delete" size="small" onClick={handleClose}>
                    <ClearIcon fontSize="medium" />

                </CloseButton>

            </DialogTitle>


            <DialogContent>

                <FormFields>
                    <form autoComplete='off'>
                        <Grid container spacing={2}>

                            <Grid item xs={3}>
                                <Typography align="left" variant='body2'
                                    sx={{ paddingBottom: 5 }}>{t('DOCUMENT_SOURCE')}</Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <FormControl>
                                    <RadioGroup
                                        aria-labelledby="controlled-radio-buttons-group"
                                        name="controlled-radio-buttons-group"
                                        value={uploadDoc ? "doc" : "web"}
                                        onChange={(ev) => {
                                            setUploadDoc(ev.target.value === "doc")
                                        }}
                                    >
                                        <FormControlLabel value="web" control={<Radio />} label="Webpage" />
                                        <FormControlLabel value="doc" control={<Radio />} label="Upload file" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>

                            {!uploadDoc && <>
                                <Grid item xs={3}>
                                    <Typography align="left" variant='body2'
                                        sx={{ paddingBottom: 5 }}>{t('DOCUMENT_NAME')}</Typography>
                                </Grid>

                                <Grid item xs={9}>
                                    <OutlinedInput variant="outlined" fullWidth value={urlName}
                                        sx={{
                                            borderWidth: 0,
                                            backgroundColor: "background.grey",
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                borderWidth: 0,
                                            }
                                        }}
                                        onChange={(event) => setUrlName(event.target.value)}
                                    />
                                </Grid>
                            </>}

                            <Grid item xs={3}>
                                <Typography align="left" variant='body2'
                                    sx={{
                                        paddingBottom: 1
                                    }}>{!uploadDoc ? t('URL') : 'File'}</Typography>
                            </Grid>

                            {!uploadDoc &&
                                <Grid item xs={9}>
                                    <OutlinedInput variant="outlined" fullWidth value={url}
                                        sx={{
                                            borderWidth: 0,
                                            backgroundColor: "background.grey",
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                borderWidth: 0,
                                            }
                                        }}
                                        onChange={(event) => setUrl(event.target.value)}
                                    />
                                </Grid>}

                            {!uploadDoc && <>
                                <Grid item xs={3}>
                                    <Typography align="left" variant='body2'
                                        sx={{ paddingBottom: 5 }}>{t('CRAWLER_DEPTH')}</Typography>
                                </Grid>


                                <Grid item xs={9}>
                                    <RadioGroup
                                        defaultValue="0"
                                        name="radio-buttons-group"
                                        value={crawlerDepth}
                                        onChange={(ev) => setCrawlerDepth(ev.target.value)}
                                    >
                                        <FormControlLabel value="0" control={<Radio />} label={t("SINGLE_PAGE")} />
                                        <FormControlLabel value="1" control={<Radio />} label={t("FOLLOW_LINKS")} />
                                        <FormControlLabel value="2" control={<Radio />} label={t("FOLLOW_LINKS_2")} />
                                        <FormControlLabel value="3" control={<Radio />} label={t("FOLLOW_LINKS_3")} />
                                        <FormControlLabel value="4" control={<Radio />} label={t("FOLLOW_LINKS_4")} />
                                    </RadioGroup>
                                    {Number(crawlerDepth) > 1 && <Alert severity="warning">{t("FOLLOW_LINKS_WARNING")}</Alert>}
                                </Grid>
                            </>}

                            {uploadDoc &&
                                <Grid item xs={12}>
                                    <DropZone
                                        onDrop={handleDrop}
                                        sx={{
                                            padding: 4,
                                            textAlign: 'center',
                                            cursor: 'pointer',
                                            marginBottom: 2,
                                        }}
                                    >
                                        <Typography variant="h6" color="textSecondary">
                                            {isDragActive ? t("RELEASE_TO_UPLOAD") : t("DRAG_AND_DROP")}
                                        </Typography>
                                        <Button variant="contained" component="label" color="secondary" sx={{ marginTop: 2 }}>
                                            <VisuallyHiddenInput
                                                multiple
                                                type="file"
                                                onChange={handleSelect} />
                                            {files && t("SELECT_FILES")}
                                        </Button>
                                    </DropZone>
                                </Grid>
                            }

                            {uploadDoc && names.length > 0 && (<>
                                <Grid item xs={4}>
                                    <Typography align="left" variant="body2" sx={{ marginTop: 2 }}>
                                        {t("SELECTED_FILES")}
                                    </Typography>
                                </Grid>
                                <Grid item xs={5}>
                                    {uploadDoc && names.length > MAX_NUMBER_OF_FILES && (
                                        <Typography align="left" variant='body2' color="secondary.main" sx={{ marginTop: 2 }}>
                                            {t("TOO_MANY_FILES")}
                                        </Typography>
                                    )}
                                </Grid>
                                <Grid item xs={3} style={{ textAlign: "right" }}>
                                    <Button
                                        sx={{
                                            width: 100,
                                            backgroundColor: 'secondary.main',
                                            color: 'secondary.selection',
                                            fontSize: 10,
                                            marginTop: 1
                                        }}
                                        variant="contained"
                                        onClick={() => {
                                            setFiles([])
                                            setNames([])
                                        }}
                                    >
                                        {t("DELETE_ALL")}
                                    </Button>
                                </Grid>
                            </>)}

                            {uploadDoc && names.length > 0 && (
                                <Grid item xs={12}>
                                    <List>
                                        {names.map((name, index) => (
                                            <ListItem key={index} secondaryAction={
                                                <IconButton
                                                    edge="end"
                                                    aria-label="Delete"
                                                    onClick={() => handleRemoveFile(index)}
                                                >
                                                    <Delete />
                                                </IconButton>
                                            }>
                                                <ListItemIcon>
                                                    <Icon src={files[index].name.toLowerCase().endsWith('pdf') ? pdf : files[index].name.toLowerCase().endsWith('doc') ? doc : file}
                                                        component="img"
                                                        sx={{
                                                            width: "auto",
                                                            height: 24
                                                        }} />
                                                </ListItemIcon>
                                                <TextField
                                                    value={name.name}
                                                    onChange={(e) => handleNameChange(index, e.target.value)}
                                                    variant="outlined"
                                                    sx={{ width: "80%" }}
                                                />
                                            </ListItem>
                                        ))}
                                    </List>
                                </Grid>
                            )}


                            {/*uploadDoc &&
                                <>

                                    <Grid item xs={3}>
                                        <Typography align="left" variant='body2'
                                            sx={{ paddingTop: 2 }}>{t("USE_OCR")}</Typography>
                                    </Grid>
                                    <Grid item xs={9}>

                                        <FormControlLabel
                                            control={<Checkbox
                                                disabled={!file || !file.name.endsWith(".pdf")}
                                                checked={file && file.name.endsWith(".pdf") && ocr}
                                                onChange={() => setOcr(prev => !prev)} />}
                                            label="" />
                                    </Grid>
                                </>
                            */}

                        </Grid>

                    </form>
                </FormFields>

            </DialogContent>

            <DialogActions
                sx={{
                    padding: 0, height: 100, width: '100%',
                }}>
                <Button
                    sx={{
                        width: '150px', margin: 1, color: 'primary.contrastText', backgroundColor: 'background.grey',
                    }}
                    variant="contained"
                    onClick={handleClose}
                    disabled={loading}
                >{t('CANCEL')} </Button>


                <Button
                    sx={{
                        width: '150px', margin: 1,
                        backgroundColor: 'primary.third',
                        color: 'primary.selection',
                    }}
                    variant="contained"
                    disabled={loading || names.length > MAX_NUMBER_OF_FILES}
                    startIcon={loading ? <CircularProgress size={22} color='secondary' thickness={5} /> : <></>}
                    onClick={() => {
                        const ret = isValid(indexConfigModel.isNew);
                        if (ret) {
                            submit();
                        }
                    }}
                > {t('SAVE')}</Button>

            </DialogActions>


        </Dialog>

    )
        ;
}

DocumentUpdate.propTypes = {};

const mapStateToProps = (store) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DocumentUpdate);


